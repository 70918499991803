import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { BsArrowLeft } from "react-icons/bs";
import styled from "styled-components";
import {
  mainBlack,
  mainRed,
  mainYellow,
  innerWidth,
  mainWhite,
  lightGrey,
  screen,
} from "../components/common/variables";
import StyledButton from "../components/styled-button";
import { formatDate } from "../helpers";
import SEO from "../components/seo";

const Wrapper = styled.div`
  .banner {
    border-top: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-top: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-top: 17px ${mainWhite} solid;
    }

    .sub-wrapper {
      display: flex;
      flex-direction: column;
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        flex-direction: row;
        padding: 100px 30px;
        min-height: 75vh;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .col {
        &--left {
          text-align: center;
          @media ${screen.small} {
            width: 45%;
            text-align: left;
          }

          .featured-txt {
            .inner-txt {
              .back-link {
                color: ${mainBlack};
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 0 30px 0;
                font-size: 0.88rem;
                letter-spacing: 2px;
                @media ${screen.xsmall} {
                  margin: 0 0 40px 0;
                }
                @media ${screen.small} {
                  justify-content: flex-start;
                }

                @media ${screen.withCursor} {
                  &:hover {
                    color: ${mainYellow};
                  }
                }

                svg {
                  font-size: 2rem;
                  margin: 0 10px 0 0;
                }
              }

              .capital-heading {
                margin: 20px 0 0 0;
              }

              .normal-p {
                margin: 20px 0 0 0;
                display: block;
                line-height: 1.5;
                .slash-symbol {
                  color: ${mainRed};
                }

                a {
                  color: ${mainBlack};
                }
              }

              .btns {
                display: flex;
                margin: 20px 0 0 0;
                flex-direction: column;
                @media ${screen.xsmall} {
                  flex-direction: row;
                  justify-content: center;
                }
                @media ${screen.small} {
                  justify-content: flex-start;
                }

                .btn {
                  margin: 0 0 18px 0;
                  @media ${screen.xsmall} {
                    margin: 0;
                  }
                }
              }
            }
          }
        }

        &--right {
          text-align: center;
          margin: 60px 0 0 0;
          @media ${screen.small} {
            width: 55%;
            margin: 131px 0 0 60px;
            text-align: left;
          }

          .subtitle {
            font-size: 1.22rem;
            font-weight: 400;
            border-bottom: 2px ${mainRed} solid;
            margin: 0 0 25px 0;
            padding: 0 0 25px 0;
            @media ${screen.xsmall} {
              font-size: 1.4rem;
              padding: 0 0 50px 0;
              margin: 0 0 50px 0;
            }
            @media ${screen.large} {
              font-size: 1.5rem;
            }
          }

          .description {
            p {
              font-size: 1rem;
              font-weight: 300;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.1rem;
              }

              &:last-child {
                margin: 0;
              }
            }

            a {
              color: ${mainRed};
              text-decoration: underline;
              @media ${screen.withCursor} {
                &:hover {
                  opacity: 0.7;
                }
              }
            }

            ul,
            ol {
              @media ${screen.small} {
                padding: 0 0 0 30px;
              }

              li {
                font-size: 1rem;
                font-weight: 300;
                margin: 0 0 20px 0;
                line-height: 1.5;
                @media ${screen.large} {
                  font-size: 1.1rem;
                }
              }
            }

            ol {
              li {
                list-style-type: disc;
                list-style-position: inside;
              }
            }
            ul {
              li {
                list-style-type: decimal;
                list-style-position: inside;
              }
            }

            h1 {
              font-size: 1.7rem;
              font-weight: 500;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.8rem;
              }
            }
            h2 {
              font-size: 1.6rem;
              font-weight: 500;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.7rem;
              }
            }
            h3 {
              font-size: 1.5rem;
              font-weight: 500;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.6rem;
              }
            }
            h4 {
              font-size: 1.4rem;
              font-weight: 500;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.5rem;
              }
            }
            h5 {
              font-size: 1.3rem;
              font-weight: 500;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.4rem;
              }
            }

            h6 {
              font-size: 1.2rem;
              font-weight: 500;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.3rem;
              }
            }

            img {
              display: block;
              width: 100%;
              margin: 30px 0;
            }
          }

          .feautured-img {
            margin: 35px 0 0 0;
            @media ${screen.xsmall} {
              margin: 60px 0 0 0;
            }
          }
        }
      }
    }
  }

  .gallery {
    background: ${lightGrey};

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 22px 50px 22px;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }
    }

    .capital-subheading {
      text-align: center;
      margin: 0 auto 30px auto;
      max-width: 210px;
      @media ${screen.xsmall} {
        margin: 0 0 35px 0;
        max-width: none;
      }
      @media ${screen.small} {
        margin: 0 0 50px 0;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -7px;

      li {
        margin: 0 0 14px 0;
        width: 100%;
        @media ${screen.xsmall} {
          width: calc(50% - 14px);
          margin: 7px;
        }
        @media ${screen.medium} {
          width: calc(33.33% - 14px);
        }
      }
    }
  }
`;

const PastEventPage = ({ data }) => {
  const {
    event_title,
    event_date,
    event_time,
    event_address,
    google_map_url,
    event_short_intro,
    event_description,
    title_tag,
    meta_description,
    gallery_list,
  } = data.content.data;

  // checker to prevent build error
  const galleryIsEmpty =
    gallery_list.length === 0 ||
    !gallery_list[0].image.thumbnails.thumbnail.gatsbyImageData;

  // show a featured image instead of gallery (if only one image is uploaded)
  const isFeaturedImg = !galleryIsEmpty && gallery_list.length === 1;

  // if image is more than 1
  const galleryToRender = gallery_list;

  return (
    <Layout>
      <SEO
        title={title_tag || event_title.text}
        description={meta_description || event_short_intro}
        image={!galleryIsEmpty && gallery_list[0].image.thumbnails.square.url}
        eventInfo={{
          name: event_title.text,
          startDate: event_date,
          location: event_address,
          image: !galleryIsEmpty && gallery_list[0].image.url,
          description: event_short_intro,
        }}
      />
      <Wrapper>
        <section className="banner">
          <div className="sub-wrapper">
            <div className="col col--left">
              <div className="featured-txt">
                <div className="inner-txt">
                  <Link className="back-link" to="/events/">
                    <BsArrowLeft />
                    BACK TO EVENTS
                  </Link>
                  <h6 className="red-heading">PAST EVENT</h6>
                  <h1 className="capital-heading">{event_title.text}</h1>
                  <span className="normal-p">
                    {formatDate(event_date)}{" "}
                    <span className="slash-symbol">//</span> {event_time}{" "}
                    <span className="slash-symbol">//</span>{" "}
                    <a href={google_map_url} target="_blank" rel="noreferrer">
                      {event_address}
                    </a>
                  </span>
                  <div className="btns">
                    <StyledButton className="btn" to="/donate/">
                      DONATE NOW
                    </StyledButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col--right">
              <h6 className="subtitle">{event_short_intro}</h6>

              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: event_description.html }}
              />
              {isFeaturedImg && (
                <div className="feautured-img">
                  {gallery_list[0].image && (
                    <GatsbyImage
                      image={gallery_list[0].image.gatsbyImageData}
                      alt={gallery_list[0].image.alt || "Event Snapshot"}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </section>

        {!isFeaturedImg && !galleryIsEmpty && (
          <section className="gallery">
            <div className="inner-wrapper">
              <h4 className="capital-subheading">Photos from the day</h4>
              <ul className="gallery__list">
                {galleryToRender.map((item, i) => (
                  <li key={i}>
                    <GatsbyImage
                      image={item.image.thumbnails.thumbnail.gatsbyImageData}
                      alt={item.image.alt || "Event Snapshot"}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </section>
        )}
      </Wrapper>
    </Layout>
  );
};

export default PastEventPage;

export const data = graphql`
  query ($id: String!) {
    content: prismicEvent(id: { eq: $id }) {
      data {
        event_title {
          text
        }
        event_date
        event_time
        event_address
        google_map_url
        event_short_intro
        event_description {
          html
        }
        title_tag
        meta_description
        gallery_list {
          image {
            alt
            url
            gatsbyImageData(placeholder: BLURRED)
            thumbnails {
              thumbnail {
                gatsbyImageData(placeholder: BLURRED)
              }
              square {
                url
              }
            }
          }
        }
      }
    }
  }
`;
